// @flow

import React, { useState, useEffect, useRef } from 'react';
import { TextField, withStyles, Button } from '@material-ui/core';
import { Block, FlexRow } from '../baseUI';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import type { ProductItem, ProductDetail, ProductCartItem } from '../appState/types';
import BasicPopover from '../appUI/BasicPopover';
import { Trans } from '@lingui/react';
import { makeStyles } from '@material-ui/core/styles';
import { useDebounceQuantity } from "./useDebounceQuantity";

const MaterialTextButton = withStyles((theme) => ({
  root: {
    minWidth: 28,
    // minHeight: 22,
    fontWeight: 600,
    fontSize: 22,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))((props: *) => {
  return <Button size="small" color="primary" variant="text" {...props} />;
});

const useStyles = makeStyles({
  root: {
    '& input': {
      width: (inputWidth) => `${inputWidth}rem`,
      padding: '0.3rem 0.5rem',
      textAlign: 'right',
      '&::-webkit-outer-spin-button': {},
    },
    '& fieldset': {
      borderRadius: 0,
    },
    /* Chrome, Safari, Edge, Opera */
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },

    /* Firefox */
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
});

function LargeAmountInput(props: {|
  max: number,
  product: ProductItem | ProductDetail | ProductCartItem,
  onChoose: Function,
  value: number,
  pieceSale: boolean,
  variant?: string,
|}) {
  const { product, value, pieceSale } = props;
  const { packageSize, measureUnit } = product.params;
  const _DELTA = 0.65;
  const orderAmount =
    measureUnit.erp_id === 'KS' || measureUnit.erp_id === 'BAL'
      ? product.params.minAmountToOrder
      : packageSize;
  const [isInvalidAmount, setIsInvalidAmount] = useState(false);

  const handleInputUpdate = () => {
    !isValidAmount(fieldAmount) && handleInvalidAmount();
    const newAmount = resolveValidAmount();
    setFieldAmount(newAmount);
    onChooseWrapper(newAmount);
  };

  const [fieldAmount, setFieldAmount] = useDebounceQuantity({
    initialValue: value,
    onChange: handleInputUpdate,
  });

  const [inputWidth, setInputWidth] = useState(
    Math.floor(fieldAmount.toString().length * _DELTA * 100) / 100
  );
  const popoverRef = useRef(null);
  const classes = useStyles(inputWidth);

  useEffect(() => {
    const newAmount = resolveValidAmount(value);
    setFieldAmount(newAmount);
    onChooseWrapper(newAmount);
  }, [value]);

  const handleInvalidAmount = () => {
    if (isInvalidAmount) return;
    setIsInvalidAmount(true);
    setTimeout(() => {
      setIsInvalidAmount(false);
    }, 2000);
  };

  const isValidAmount = (val: number) => {
    const baseValid = val >= 0 && val <= props.max;
    if (pieceSale) {
      return baseValid;
    } else {
      // package sale
      const isPackageSize = val % orderAmount === 0;
      return baseValid && isPackageSize;
    }
  };

  const resolveValidAmount = (inputAmount?: number): number => {
    const amountToCheck = inputAmount !== undefined ? inputAmount : fieldAmount;
    if (pieceSale) {
      return amountToCheck < 0 ? 0 : amountToCheck > props.max ? props.max : amountToCheck;
    } else {
      // sold by packages
      const nextMostPieces =
        amountToCheck > 0 ? Math.ceil(amountToCheck / orderAmount) * orderAmount : 0;
      return nextMostPieces > props.max
        ? Math.floor(props.max / orderAmount) * orderAmount
        : nextMostPieces;
    }
  };

  const handleIncrement = () => {
    const changeBy = pieceSale ? 1 : orderAmount;
    let newAmount = parseInt(resolveValidAmount()) + changeBy;
    if (newAmount <= props.max) {
      setFieldAmount(newAmount);
      onChooseWrapper(newAmount);
    }
  };

  const handleDecrement = () => {
    const changeBy = pieceSale ? 1 : orderAmount;
    let newAmount = parseInt(resolveValidAmount()) - changeBy;
    if (newAmount >= 0) {
      setFieldAmount(newAmount);
      onChooseWrapper(newAmount);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleInputUpdate();
  };

  const updateWidth = (value) => {
    if (fieldAmount.toString().length !== value.toString().length) {
      const newValue = value.toString().length * _DELTA;
      setInputWidth(Math.floor(newValue * 100) / 100);
    }
  }

  const onChooseWrapper = (value) => {
    props.onChoose(value);
    updateWidth(value);
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateWidth(e.target.value);
    setFieldAmount(e.target.value);
  }

  return (
    <form onSubmit={(e) => handleSubmit(e)} style={{ margin: '0' }} ref={popoverRef}>
      <FlexRow alignItems="center">
        <MaterialTextButton
          style={{ borderRight: '0' }}
          onClick={handleDecrement}
          name="decrement"
          data-cy="decrement-icon"
        >
          <RemoveIcon />
        </MaterialTextButton>
        <TextField
          className={classes.root}
          value={fieldAmount}
          variant={props.variant || 'outlined'}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleAmountChange}
          onBlur={handleInputUpdate}
          data-cy="amount-input"
        />
        <MaterialTextButton
          style={{ borderLeft: '0' }}
          onClick={handleIncrement}
          name="increment"
          data-cy="increment-icon"
        >
          <AddIcon />
        </MaterialTextButton>
        <BasicPopover
          isVisible={isInvalidAmount}
          anchorEl={popoverRef.current}
          placement="bottom"
        >
          <Block padding={10} whiteSpace="pre">
            <Trans>Adjusted to the closest available/allowed value</Trans>
          </Block>
        </BasicPopover>
      </FlexRow>
    </form>
  );
}

export default LargeAmountInput;
